function handelValidationErrors(errors) {

}

function handel(code, errors = null) {
  switch (code) {
    case 404:
      return "لم يتم العثور على البيانات المطلوبة"
    case 500:
      return "خطأ في الاتصال مع المخدم"
    case 403:
      return "لا يمكنك القيام بهذا الاجراء"
    case 401:
      return "انتهت صلاحية الجلسة"
    case 400:
      return errors ?? "خطأ في الاتصال مع المخدم"
    case 422:
      if (errors) {
        return handelValidationErrors(errors)
      } else {
        return "خطأ في البيانات المدخلة"
      }
    default:
      return "خطأ غير معروف"
  }
}


export const handelErrorsService = {
  handel
}
