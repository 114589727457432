import React from 'react'
import { Button } from 'react-bootstrap'
import { Styles } from './styles'

export default function MainButton({ onClick, text, type = "button", disabled = false }) {
  return (
    <Styles>
      <Button className='btn rounded text-center  py-2 px-5 main-btn w-100' onClick={onClick} type={type} disabled={disabled}>{text}</Button>
    </Styles>
  )
}
