const config = {
  API_URL: "https://admin-booker.akwad4it.com/api/",
  API_LINK_STORAGE: "https://admin-booker.akwad4it.com/"
}

const env = {
  ...config,
}

export default env
