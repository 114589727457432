import styled from "styled-components";
export const Styles = styled.div`

   .spinner-border{
    border-color: #fff;
    border-left-color: transparent;
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  --bs-spinner-vertical-align: -0.8em;
  --bs-spinner-border-width: 0.20em;
   }

`;
