import React from 'react'
import { Spinner } from 'react-bootstrap'
import { Styles } from './styles'

export default function SpinnerLoading() {
    return (
        <Styles>
            <Spinner />
        </Styles>
    )
}
