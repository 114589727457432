/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import env from '../../../env.js'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../../../assets/images/logo.png'


const DefaultLayout = ({ children, desc }) => {
    const { name } = useParams()
    const navigate = useNavigate()
    const { profile } = useSelector(state => state.profile)


    useEffect(() => {
        if (profile == null)
            navigate(`/${name}`)
    }, [name, navigate, profile])

    return (
        <>
            <div style=
                {{
                    position: 'fixed', display: 'block', marginInline: 'auto', textAlign: 'center', width: '100%',
                    zIndex: '10000000', backgroundColor: '#7136E085', padding: '5px'
                }} >
                <a href='https://admin-booker.akwad4it.com/register' target='_blanck' style={{ color: 'var(--secondary-color)' }} >
                    انضم الينا الأن
                    <img src={logo} alt='logo' width='25px' className='mx-2' />
                </a>
            </div>
            <header>
                <nav className='py-5'>
                    <div className='container border-bottom'>
                        <div className='row align-items-center py-3 justify-content-between'>
                            <div className='col-lg-6 user-info-aria m-auto' style={{width:'fit-content'}}>
                                <h1 className='m-0 h3 d-flex align-items-center'>
                                    {profile.image == null ?
                                        <span className="material-symbols-outlined">
                                            account_circle
                                        </span>
                                        :
                                        <img className='img-fluid img-thumbnail rounded-circle' width={30} height={30} alt='' src={env.API_LINK_STORAGE + profile.image} />
                                    }
                                    <span className='px-2'>{profile.shop.name ?? profile.name}</span>
                                </h1>
                                <p className='text-muted h6 w-75'>
                                    {profile.shop.description}
                                </p>
                                <ul className="list-group list-group-flush border-0 mt-3">
                                    {profile.phoneNumber && <li className="list-group-item border-0 m-0 p-0 text-muted h6">{profile.phoneNumber}</li>}
                                    {profile.email && <li className="list-group-item border-0 m-0 p-0 text-muted h6">{profile.email}</li>}
                                </ul>
                                <button className='btn btn-color my-3 py-1 px-3 d-flex align-items-center'>
                                    <span className="material-symbols-outlined">
                                        schedule
                                    </span>
                                    <span className='px-1'>مدة المقابلة: </span>
                                    {profile.shop.serviceTime} دقيقة
                                </button>
                                <p className='text-muted'>
                                    {desc}
                                </p>
                            </div>
                            <div className='col-lg-6 d-flex m-auto justify-content-end align-align-items-center text-center text-muted user-image-aria' style={{width:'fit-content'}}>
                                {profile.shop.image != null && <img className='img-fluid img-thumbnail rounded-circle p-1' width={150} height={150} alt='shop logo' src={env.API_LINK_STORAGE + profile.shop.image} />}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <main style={{ minHeight: '50vh' }}>
                {children}
            </main>
            <footer>
                <div className='container'>
                    <p className='m-0'> <a href='https://www.facebook.com/akwad4it' target="_blank" className='link-light' >Akwad company</a> - 2023 &copy;</p>
                </div>
            </footer>
        </>
    )
}

export default DefaultLayout
