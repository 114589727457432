import { createSlice } from '@reduxjs/toolkit'

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        isLoadingProfile: false,
        profile: null,
        errorProfile: null
    },
    reducers: {
        gettingProfileStart: (state) => {
            state.isLoadingProfile = true
            state.profile = null
            state.errorProfile = null
        },
        gettingProfileSuccess: (state, actions) => {
            state.isLoadingProfile = false
            let holidays = []
            actions.payload.shop.string_holidays.forEach(element => {
                holidays.push((parseInt(element) - 1).toString())
            })
            const data = {
                id: actions.payload.id,
                name: actions.payload.name,
                email: actions.payload.email,
                username: actions.payload.username,
                city: {
                    id: actions.payload.city.id,
                    name: actions.payload.city.name,
                    longitude: actions.payload.city?.longitude,
                    latitude: actions.payload.city?.latitude,
                },
                cityId: actions.payload.city_id,
                phoneNumber: actions.payload.phone_number,
                currentDates: actions.payload.current_dates,
                birthday: actions.payload.birthday,
                address: actions.payload?.address,
                status: actions.payload.status,
                type: actions.payload.type,
                image: actions.payload?.image,
                createdAt: actions.payload.created_at,
                bookedDates: actions.payload.booked_dates,
                remainingDays: actions.payload.remaining_days,
                shop: {
                    id: actions.payload.shop.id,
                    name: actions.payload.shop?.name,
                    userId: actions.payload.shop.user_id,
                    description: actions.payload.shop?.description,
                    longitude: actions.payload.shop?.longitude,
                    latitude: actions.payload.shop?.latitude,
                    address: actions.payload.shop?.address,
                    timeZone: actions.payload.shop?.time_zone,
                    serviceTime: actions.payload.shop?.service_time,
                    image: actions.payload.shop?.image,
                    categoryId: actions.payload.shop.category_id,
                    createdAt: actions.payload.shop.created_at,
                    times: {
                        openTime: actions.payload.shop.times.open_time,
                        closeTime: actions.payload.shop.times.close_time,
                        breakTimeStart: actions.payload.shop.times.break_time_start,
                        breakTimeEnd: actions.payload.shop.times.break_time_end,
                    },
                    stringHolidays: holidays,
                    category: {
                        id: actions.payload.shop.category.id,
                        name: actions.payload.shop.category.name,
                        description: actions.payload.shop.category?.description,
                        image: actions.payload.shop.category?.image,
                    },
                },
            }
            state.profile = data
            state.errorProfile = null
        },
        gettingProfileFiled: (state, actions) => {
            state.isLoadingProfile = false
            state.profile = null
            state.errorProfile = actions.payload
        },
    },
})

export const { gettingProfileStart, gettingProfileSuccess, gettingProfileFiled } = profileSlice.actions
export default profileSlice.reducer
