/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { useDispatch, useSelector } from 'react-redux'
import * as formik from 'formik'
import * as Yup from 'yup'
import { FloatingLabel, Form as BootstrapForm } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { addDateFiled, addDateStart, addDateSuccess } from '../../slices/addDateSlice'
import { appService } from '../../services/app.service'
import { toasts } from '../../components/toasts'
import { handelErrorsService } from '../../services/handelErrors.service'
import MainButton from '../../components/buttons/mainButton/MainButton'
import BtnLoading from '../../components/loading/btnLoading/BtnLoading'
import CountryCodeArray from '../../components/countryCodeArray/countryCodeArray'



const validationSchema = Yup.object().shape({
    name: Yup.string().required("الاسم الكامل مطلوب"),
    email: Yup.string().email("صيغة البريد غير صالحة"),
    phone_number: Yup.string().required("رقم الموبايل مطلوب"),
    title: Yup.string().required("عنوان المقابلة مطلوب").min(10, "يجب على الاقل ان يكون العنوان من 10 محارف")
        .max(200, "يجب على الأكثر ان يكون العنوان من 200 محارف"),
    subject: Yup.string().required("موضوع المقابلة مطلوب").min(10, "يجب على الاقل ان يكون الموضوع من 10 محارف")
        .max(4000, "يجب على الأكثر ان يكون الموضوع من 4000 محارف"),
    birthday: Yup.date('صيغة التاريخ غير صالحة'),
    notes: Yup.string().min(10, "يجب على الاقل ان تكون الملاحظات من 10 محارف")
        .max(4000, "يجب على الأكثر ان تكون الملاحظات من 4000 محارف"),
})



export default function AddDatePage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { Formik } = formik
    const { profile } = useSelector(state => state.profile)
    const { isLoadingAddDate, errorAddDate, date, countryCode } = useSelector(state => state.addDate)


console.log(profile)
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            dispatch(addDateStart())
            const data = {
                'user_id': profile.id,
                'name': values.name,
                'phone_number': countryCode?.value.toString() + values.phone_number.toString(),
                'title': values.title,
                'subject': values.subject,
                'birthday': values.birthday,
                'notes': values.notes,
                'time': date
            }
            const res = await appService.addDate(data)
            if (res.status === 200) {
                if (res.data.success) {
                    dispatch(addDateSuccess())
                    navigate(`/${profile.username}`)
                    toasts.success("تم ارسال طلب الحجز بنجاح سيتم اعلامك بالنتيجة قريبا")
                } else {
                    dispatch(addDateFiled(res.error))
                    toasts.error(errorAddDate)
                }
            } else {
                const errorRes = handelErrorsService.handel(res.status)
                dispatch(addDateFiled(errorRes))
                toasts.error(errorAddDate)
            }
        } catch (error) {
            dispatch(addDateFiled())
            toasts.error()
        } finally {
            setSubmitting(false)
        }
    }

    return (
        profile != null ?

            <DefaultLayout desc={"برجى ادخال البيانات المطلوبة"}>
                <div className='container d-flex align-items-center justify-content-center py-4'>
                    <div className='card p-3 w-50 shadow add-date-form'>
                        <Formik initialValues={{ name: '', email: '', phone_number: '', title: '', subject: '', birthday: '', notes: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            {({ handleChange, values, touched, errors, isSubmitting }) => (
                                <formik.Form>
                                    <div className='row mb-3'>
                                        <div className='col-lg-12'>
                                            <FloatingLabel controlId="name" label="الاسم كامل *" className={'mb-3'}>
                                                <BootstrapForm.Control type="text" name="name" placeholder="مثال: أمجد الشاه" value={values.name}
                                                    onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && errors.name} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-12'>
                                            <FloatingLabel controlId="email" label="البريد الالكتروني" className={'mb-3'}>
                                                <BootstrapForm.Control type="email" name="email" placeholder="example@example.com" value={values.email}
                                                    onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={touched.email && errors.email} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='row'>
                                                <div className='col-9 pe-0'>
                                                    <FloatingLabel controlId="phoneNumber" label="رقم الموبايل *" className={'mb-3'}>
                                                        <BootstrapForm.Control type="number" name="phone_number" placeholder="963993004230" value={values.phone_number}
                                                            onChange={handleChange} isValid={touched.phone_number && !errors.phone_number} isInvalid={touched.phone_number && errors.phone_number} />
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.phone_number}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                </div>
                                                <div className='col-3 ps-0'>
                                                    <CountryCodeArray />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <FloatingLabel controlId="birthday" label="تاريخ الميلاد" className={'mb-3'}>
                                                <BootstrapForm.Control type="date" name="birthday" value={values.birthday}
                                                    onChange={handleChange} isValid={touched.birthday && !errors.birthday} isInvalid={touched.birthday && errors.birthday} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.birthday}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-12'>
                                            <FloatingLabel controlId="title" label="عنوان المقابلة *" className={'mb-3'}>
                                                <BootstrapForm.Control type="text" name="title" placeholder="مثال: لدي مشكلة.." value={values.title}
                                                    onChange={handleChange} isValid={touched.title && !errors.title} isInvalid={touched.title && errors.title} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.title}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-12'>
                                            <FloatingLabel controlId="subject" label="موضوع المقابلة *" className={'mb-3'}>
                                                <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="subject" value={values.subject}
                                                    onChange={handleChange} isValid={touched.subject && !errors.subject} isInvalid={touched.subject && errors.subject} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.subject}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-12'>
                                            <FloatingLabel controlId="notes" label="الملاحظات" className={'mb-3'}>
                                                <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="notes" placeholder="أدخل الملاحظات ان وجدت" value={values.notes}
                                                    onChange={handleChange} isValid={touched.notes && !errors.notes} isInvalid={touched.notes && errors.notes} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.notes}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <MainButton type="submit" disabled={isSubmitting} text={isLoadingAddDate ? <BtnLoading /> : "إضافة"} />
                                    </div>
                                </formik.Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </DefaultLayout>
            : "")
}
