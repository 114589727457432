import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import AddDatePage from './pages/addDate/AddDatePage';

function App() {
  return (
    <Routes>
      <Route path='/:name' element={<HomePage />} />
      <Route path='/new-date' element={<AddDatePage />} />
    </Routes>
  );
}

export default App;
