import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const success = (message) =>
  toast.success(message || "تمت العملية بنجاح", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    rtl: true,
    progress: undefined,
    color: "#e3e3e3",
  });


const error = (message) =>
  toast.error(message || "حدث خطأ ما", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    rtl: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


const info = (message) =>
  toast.info(message || "حدث خطأ ما", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    rtl: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const warning = (message) =>
  toast.warning(message || "حدث خطأ ما", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    rtl: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const toasts = {
  success, error, info, warning
}
