import { createSlice } from '@reduxjs/toolkit'

const addDateSlice = createSlice({
    name: 'addDate',
    initialState: {
        isLoadingAddDate: false,
        errorAddDate: null,
        date: null,
        countryCode : 0
    },
    reducers: {
        addDateStart: (state) => {
            state.isLoadingAddDate = true
            state.errorAddDate = null
        },
        addDateSuccess: (state, actions) => {
            state.isLoadingAddDate = false
            state.errorAddDate = null
        },
        addDateFiled: (state, actions) => {
            state.isLoadingAddDate = false
            state.errorAddDate = actions.payload
        },
        setDate: (state, actions) => {
            state.date = actions.payload
        },
        setCountryCode: (state , {payload}) => {
            state.countryCode = payload
        }
    },
})

export const { addDateStart, addDateSuccess, addDateFiled, setDate , setCountryCode } = addDateSlice.actions
export default addDateSlice.reducer
