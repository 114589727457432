import styled from "styled-components";
export const Styles = styled.div`

   .main-btn{
    color: #fff !important;
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 900;
    background: var(--main-color);
    cursor: pointer;
    transition: 0.3s;
   }

   .main-btn:hover {
      background: transparent;
      color: var(--main-color) !important;
      border: 1px solid var(--main-color);
   }

   .main-btn.disabled {
      background: #ccc;
      color: var(--secondary-color);
      border: 1px solid #ccc;
   }
`;
