import { configureStore } from "@reduxjs/toolkit"
import profileSlice from "./slices/profileSlice"
import addDateSlice from "./slices/addDateSlice"

const store = configureStore({
  reducer: {
    profile: profileSlice,
    addDate: addDateSlice
  }
})

export default store
