/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { useDispatch, useSelector } from "react-redux"
import App from "./App"
import { Provider } from "react-redux"
import store from "./store"
import "bootstrap/dist/css/bootstrap.rtl.min.css"
import "./assets/css/styles.css"
import { BrowserRouter, useParams } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { appService } from "./services/app.service"
import MainButton from "./components/buttons/mainButton/MainButton"
import SpinnerLoading from "./components/loading/spinnerLoading/SpinnerLoading"

const Main = () => {
  const { isLoadingProfile, errorProfile, profile } = useSelector((state) => state.profile)
  const dispatch = useDispatch()
  const { name } = useParams()

  useEffect(() => {
    if (profile == null && name != null && errorProfile != null)
      appService.getProfile(dispatch, name)
  }, [])

  return (
    <React.Fragment>
      <BrowserRouter>
        <ToastContainer />
        {isLoadingProfile ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <SpinnerLoading />
          </div>
        ) : errorProfile ? (
          <div className="vh-100 d-flex justify-content-center align-items-center d-flex flex-column">
            <p className="mb-3 text-danger">{errorProfile}</p>
            {name && name != null ?
              <MainButton type="button" text={"اعادة المحاولة"} onClick={async () => await appService.getProfile(dispatch, name)} />
              :
              <p className="mb-3 text-danger">{"الرابط المدخل غير صالح"}</p>
            }
          </div>) : (
          <App />
        )}
      </BrowserRouter>
    </React.Fragment>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <Main />
  </Provider>
)
