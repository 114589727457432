/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import AmanTimeBtn from '../../components/AmanTimeBtn'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setDate } from '../../slices/addDateSlice'
import moment from 'moment'
import { appService } from '../../services/app.service'
import DateTypes from '../../enums/dateTypes'
// import 'moment/locale/ar'

export default function HomePage() {
    const { name } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { profile } = useSelector(state => state.profile)

    const [currentWeek, setCurrentWeek] = useState(moment().week())
    const [isFirstWeek, setIsFirstWeek] = useState(currentWeek == moment().week())
    const startOfWeek = moment().isoWeek(currentWeek).startOf('isoWeek')
    const endOfWeek = moment().isoWeek(currentWeek).endOf('isoWeek')
    const startMonth = startOfWeek.format('MMMM')
    const endMonth = endOfWeek.format('MMMM')
    const startYear = startOfWeek.format('YYYY')
    const endYear = endOfWeek.format('YYYY')
    const isTwoMonths = startMonth !== endMonth
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        moment.locale('en-US')
        setIsFirstWeek(currentWeek == moment().week())
        if (profile == null)
            appService.getProfile(dispatch, name)


        const mediaQuery = window.matchMedia('(max-width: 768px)');

        const handleResize = (e) => {
            setIsMobile(e.matches);
        };

        // Initial check
        handleResize(mediaQuery);

        // Add event listener to check for changes in media query
        mediaQuery.addEventListener('change', handleResize);

        // Clean up event listener on component unmount
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };

    }, [currentWeek, name])


    // events functions ............................. start
    function newDate(date, time) {
        const fullDateTime = date.format('MM-DD-YYYY') + ', ' + time
        dispatch(setDate(fullDateTime))
        navigate('/new-date')
    }
    // events functions ............................. end


    // Date Peakier Functions .................................... start
    const renderDaySchedule = () => {
        const today = moment()
        let existingTimeSlots = []
        let existingExceptionalHolidays = []
        let existingOfficialHolidays = []
        profile.currentDates.forEach(element => {
            if (element.type == DateTypes.bookedDates) {
                existingTimeSlots.push(element.date)
            } else if (element.type == DateTypes.officialHolidays) {
                existingOfficialHolidays.push(element.date.replace(/^(\d{4})/, startYear))
            }
            else if (element.type == DateTypes.exceptionalHolidays) {
                existingExceptionalHolidays.push(element.date)
            }
        });

        const days = Array.from({ length: 7 }).map((_, index) => {
            const currentDate = moment().week(currentWeek).startOf('week').add(index - 1, 'day')
            const dayNumber = currentDate.format('D')
            const dayName = currentDate.format('dddd')

            // Generate time slots for the day
            const timeSlots = []
            const startTime = moment(profile.shop.times.openTime, 'h:mm A')
            const endTime = moment(profile.shop.times.closeTime, 'h:mm A')
            const breakStartTime = profile.shop?.times?.breakTimeStart ? moment(profile.shop.times.breakTimeStart, 'h:mm A') : null
            const breakEndTime = profile.shop?.times?.breakTimeEnd ? moment(profile.shop.times.breakTimeEnd, 'h:mm A') : null

            let holidays = profile.shop.stringHolidays.map(element => parseInt(element));

            let currentTime = startTime.clone()
            while (currentTime.isBefore(endTime)) {
                // Exclude break time slots
                if (breakStartTime != null || breakEndTime != null) {

                    if (currentTime.isBefore(breakStartTime) || currentTime.isSameOrAfter(breakEndTime)) {
                        const formattedTime = currentTime.format('hh:mm A')
                        const fullDateTime = currentDate.format('MM-DD-YYYY') + ', ' + formattedTime

                        // Check if the time slot is in the existing array
                        const isDisabled = existingTimeSlots.includes(fullDateTime)

                        timeSlots.push({
                            time: formattedTime,
                            isDisabled: isDisabled,
                        })
                    }
                } else {
                    const formattedTime = currentTime.format('hh:mm A')
                    const fullDateTime = currentDate.format('MM-DD-YYYY') + ', ' + formattedTime

                    // Check if the time slot is in the existing array
                    const isDisabled = existingTimeSlots.includes(fullDateTime)

                    timeSlots.push({
                        time: formattedTime,
                        isDisabled: isDisabled,
                    })
                }

                currentTime.add(profile.shop.serviceTime, 'minutes')
            }
            const isBeforeToday = currentDate.isBefore(today, 'day');

            const isToday = currentDate.isSame(today, 'day')

            let isHoliday = isBeforeToday || holidays.includes(index) || existingOfficialHolidays.includes(currentDate.format('YYYY-MM-DD')) || existingExceptionalHolidays.includes(currentDate.format('YYYY-MM-DD')) || false

            return (
                <div key={index} className={`border position-relative rounded time-picker-column d-flex flex-column justify-content-center align-items-center px-2 py-3  ms-1 date-packer-day ${isHoliday && isMobile ? 'overflow-hidden' : ''}`}>
                    {isHoliday && <div className='position-absolute w-100 h-100 time-picker-column-overlay'></div>}
                    {isToday && <span className='position-absolute top-0 translate-middle badge rounded-pill bg-color today-badge'>اليوم</span>}
                    <div className='w-100 time-picker-column-header mb-4'>
                        <span className='d-block day-name text-end'>{dayName}</span>
                        <span className='d-block day-number'>{dayNumber}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        {timeSlots.map((timeSlot, idx) => (
                            <AmanTimeBtn
                                key={idx}
                                text={timeSlot.time}
                                onClick={() => newDate(currentDate, timeSlot.time)}
                                disabled={timeSlot.isDisabled}
                            />
                        ))}
                    </div>

                </div>
            )
        })

        return days
    }


    const getNextWeek = () => {
        setCurrentWeek(currentWeek + 1)
    }

    const getPreviousWeek = () => {
        if (currentWeek > moment().week()) {
            setCurrentWeek(currentWeek - 1)
        }
    }
    // Date Peakier Functions .................................... end


    return (
        profile != null ?
            <DefaultLayout desc={"برجى اختيار الموعد الذي تريده"} className=''>
                <nav className='mb-4'>
                    <div className='container'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='btn-group switch-date-btn-aria' role="group">
                                <button type="button" className="btn btn-color" onClick={getPreviousWeek} disabled={isFirstWeek}>
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </button>
                                <button type="button" className="btn btn-color" onClick={getNextWeek}>
                                    <span className="material-symbols-outlined">
                                        chevron_left
                                    </span>
                                </button>
                            </div>
                            <div className='date-aria h5 text-color m-0'>
                                {isTwoMonths
                                    ? `${startMonth} - ${endMonth} ${startYear !== endYear ? `${startYear} - ${endYear}` : ''}`
                                    : `${startMonth} ${startYear}`}
                            </div>
                        </div>
                    </div>
                </nav>
                <section className='time-picker-section '>
                    <div className='container'>
                        <div className='py-4 d-flex justify-content-between align-items-start date-packer'>
                            {renderDaySchedule()}
                        </div>
                    </div>
                </section>
            </DefaultLayout>
            : ""
    )
}
