import { gettingProfileFiled, gettingProfileStart, gettingProfileSuccess } from "../slices/profileSlice"
import { axiosService } from "./axios.service"

async function getProfile(dispatch, name) {
    dispatch(gettingProfileStart())
    const res = await axiosService.get(`user/${name}`)
    if (res.status === 200) {
        dispatch(gettingProfileSuccess(res.data.data))
    } else {
        dispatch(gettingProfileFiled(res.error.message))
    }
}


async function addDate(data) {
    const res = await axiosService.post('addDate', data)
    return res
}

export const appService = {
    getProfile,
    addDate
}